@include media-breakpoint-down (xs) {
	body{
		min-width: 320px;
	}
	.container{
		width: 90%;
		min-width: 320px;
	}
}


@include media-breakpoint-up(sm) {
	.container{
		width: map-get($container-max-widths, sm);
	}
}
@include media-breakpoint-up(md) {
	.container{
		width: map-get($container-max-widths, md);
	}
}

@include media-breakpoint-up(lg) {
	.container{
		width: map-get($container-max-widths, lg);
	}
}

@include media-breakpoint-up(xl) {
	.container{
		width: map-get($container-max-widths, xl);
	}
}
