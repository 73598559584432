main:first-of-type{
	margin-top: $nav-bar-height;
}
.parent{
	&--header{
		background: $white;
		color:$base;
		&.sticky{
			border-bottom:1px solid $black;
		}
	}
	&--content{
		min-height: 500px;
	}
	&--footer{
		background: #EDEDED;
		color:$base;
		// @include row-margin;
	}
	&--copyright{

	}
}
.row{
	.section__title{
		// text-transform: uppercase;
		// text-transform: capitalize;
		text-align: center;
		font-size: 20px;
		width: 100%;
		margin: 0 0 50px;
		cursor: default;
		span{
			position: relative;
			display: inline-block;
			padding-bottom: 10px;
			&:after{
				content: "";
				height: 11px;
				width: 11px;
				position: absolute; 
				bottom: -10px;
				left: calc(50% - 5.5px);
				background: $alt;
				border-radius: 100%;
			}
		}
	}
	&--header{
		.logo{
			position: relative;
			z-index: 91;
			img{
				height: 60px;
				margin: 5px 20px 0;
			}
		}
	}
	&--hero{
		@include media-breakpoint-up(sm){
			height: calc(100vh - #{$nav-bar-height});
		}
		min-height: 600px;
		overflowX: hidden;
		position: relative;
		.hero{
			&__elem{
				width: 50%;
				position: relative;
				z-index: 1;
				min-height: 200px;
				background-position: center;
				background-size: cover;
				background-blend-mode: multiply;
				> *{
					cursor: default;
				}
				@include media-breakpoint-down(xs){
					width: 100%;
				}
				
				&:hover{
					.hero{
						&__wrap{
							background-color:rgba($base,0.4) !important;
						}
						&__title,&__logo{
							visibility:hidden;
							opacity: 0;
						}
						&__content{
							visibility:visible;
							opacity: 1;	
						}
					}
				}
			}
			&__wrap{
				@include absfull;
				display: flex;
				align-items: center;
				flex-direction: column;
				justify-content: center;
				color:$white;
				flex-wrap: wrap;
				transition: 0.3s;
			}
			&__title{
				width: 80%;
				text-align: center;
				transition: 0.3s;
				opacity: 1;
				min-height: 50px;
				font-size: 25px;
				margin: 0 0 20px;
				text-transform: uppercase;
				@include media-breakpoint-up(xl){
					width: 50%;
				}
			}
			&__logo{
				transition: 0.3s;
				opacity: 1;
				height: 150px;
				img{
					max-height: 100%;
					max-width: 250px;
					filter:brightness(0) invert(1);
				}
			}
			&__content{
				// display: none;
				@include absfull;
				display: flex;
				flex-wrap: wrap;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				visibility:hidden;
				opacity: 0;
				transition: .3s;
				cursor: pointer;
				&--subtitle{
					width: 100%;
					text-align: center;
					font-size: 25px;
					margin: 0 0 20px
				}
				&--desc{
					width: 50%;
					text-align: center;
					@include media-breakpoint-down(sm){
						width: 80%;
					}
				}
			}
			@include media-breakpoint-up(sm){
				&__logo2{
					position: absolute;
					z-index: 10;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					width: 150px;
					height: 150px;
					border-radius: 100%;
					// background: $white;
					img{
						max-width: calc(100% - 4px);
						margin: 2px;
					}
				}
			}
			@include media-breakpoint-up(xl){
				&__logo2{
					width: 200px;
					height: 200px;
				}
			}
		}
	}
	&--about-us{
		display: flex;
		justify-content: center;
		text-align: center;
		.about-us{
			&__content{
				font-size: 16px;
				line-height: 1.8;
				margin: 0 auto;
				max-width: 80%;
				@include media-breakpoint-down(sm){
					max-width: 100%;
				}
			}
		}
		@include row-margin;
	}
	&--support{
		display: flex;
		@include row-margin;
		.support{
			&__inner{
				width: 100%;
				flex-wrap: wrap;
				justify-content: center;
				align-items: center;
				display: flex;
				padding: 30px;
				transition: .3s;
				border:4px solid $black;
				img{
					max-width: 128px;
				}
				.title{
					width: 100%;
					text-align: center;
					margin: 20px 0 0;
				}
				.suported{
					font-size:26px;
					font-weight: $bold;
				}
			}
			&__wrap .col{
				&:nth-child(1) .support__inner{
					border:4px solid #0088B0;
				}
				&:nth-child(2) .support__inner{
					border:4px solid #FFCF4F;
				}
				&:nth-child(3) .support__inner{
					border:4px solid #A9C35A;
				}	
				&:nth-child(4) .support__inner{
					border:4px solid#D70141;
				}
				&:hover{
					.support__inner{
						color:$white;
						img{
							filter:brightness(0%) invert(1);
						}
					}
					&:nth-child(1) .support__inner{
						background-color:#0088B0;
					}
					&:nth-child(2) .support__inner{
						background-color:#FFCF4F;
					}
					&:nth-child(3) .support__inner{
						background-color:#A9C35A;
					}	
					&:nth-child(4) .support__inner{
						background-color:#D70141;
					}
				}
			}
		}
		.col{
			display: flex;
			flex-wrap: wrap;
			&--1of3{
				width: 40%;
			}
			&--2of3{
				width: 60%;
			}
			&--2:nth-child(-n+2){
				margin-bottom: 30px;
			}
			@include media-breakpoint-down(md){
				&--1of3, &--2of3{
					width: 100%;
				}
				&--1of3{
					margin: 0 15px 30px;
				}
			}
			@include media-breakpoint-only(sm){
				&--2{
					width: 50%;
					&:nth-child(odd){
						padding: 0 15px 0 0; 
					}
					&:nth-child(even){
						padding: 0 0 0 15px; 
					}
				}
			}
			@include media-breakpoint-down(sm){
				&--1of3{
					margin: 0 0 30px;
				}
			}
		}
	}
	&--blog{
		display: flex;
		flex-wrap: wrap;
		@include row-margin;
		.blog{
			&__elem{
				.post{
					&__thumbnail{
						height: 200px;
						@include background;
					}
					&__title{
						width: 100%;
						font-weight: $bold;
						font-size: 16px;
						margin: 0;
						height: 70px;
						display: inline-flex;
						align-items: center;
					}
					&__content{
						font-size: 14px;
						height: 150px;
					}
				}
			}
		}
	}
	&--partners{
		background: #F6F6F6;
		@include row-margin;
		padding: 50px 0;
		.row{
			justify-content: flex-start;
		}
		.partner{
			&__elem{
				@extend .col;
				width: 25%;
				display: flex;
				align-items: center;
				@include media-breakpoint-down(sm){
					width: 50%;
				}
				img{
					max-width: 100%;
					filter:grayscale(100%);
					transition: .5s;
				}
				&:hover{
					img{
						filter:grayscale(0%);		
					}
				}
			}
			&__title{
				text-align: center;
				font-size: 14px;
			}
		}
	}
	&--footer{
		padding: 0 0 40px;
		font-size: 16px;
		flex-wrap:wrap;
		.logo,h5{
			margin: 30px 0;
			height: 40px;
			align-items: center;
			display: inline-flex;
			// justify-content: center;
		}
		h5{
			width: 100%;
			font-size: 18px;
			text-transform: uppercase;
		}
		.col{
			@include media-breakpoint-down( md ){
				&--3{
					width: 50%;
				}
				&:nth-of-type(1){
					display: none;
				}
			}
			@include media-breakpoint-down( xs ){
				&--3{
					width: 100%;
					justify-content: center;
					align-items: center;
					h5{
						justify-content: center;
						align-items: center;
						text-align: center;
					}
				}
			}
			@include media-breakpoint-up( lg ){
				&:nth-of-type(n+2) > *{
					padding-left: 40%;
				}
			}
			align-items: flex-start;
			flex-direction: column;
			p{
				padding: 0;
				margin: 0;
			}
		}
		ul{
			list-style: none;
			margin: 0;
			padding: 0;
			li{
				margin: 0;
				padding: 0;
			}
		}
		.icon{
			display: inline-flex;
			align-items: center;
			&:before{
				font-family: $ico-font;
				font-size:30px;
				text-align: center;
				width: 40px;
				display: inline-block;
			}
			&--tel:before{
				content:"\f095"
			}
			&--mail:before{
				content:"\f003"
			}
		}
	}
	&--copyright{
		padding: 20px 0;
		font-size: 14px;
		.col{
			align-items: center;
			@include media-breakpoint-down( xs) {
				&--2{
					width: 50%;
				}
			}
			&:last-of-type{
				display: flex;
				justify-content: flex-end;
				@include media-breakpoint-down( md ) {
					span{
						display: none;
					}	
				}
			}
		}
		.zensite{
			max-height: 25px;
			margin-left: 20px;
		}
	}
}