.cookie-bar {
	width: 100%;
	height: 60px;
	box-sizing: border-box;
	position: fixed;
	bottom: -60px;
	z-index: 9999;
	background: rgba(232, 232, 232, 0.7);
	padding: 15px 0;
	text-align: center;
	font-size: 14px;
	line-height: 1;
		.accept-btn {
		display: inline-block;
		box-sizing: border-box;
		vertical-align: middle;
		text-decoration: none;
		margin: 0 12px;
		transition: all 300ms ease-in-out;
		/*  Stylujemy pod projekt  */
		background: $base;
		padding: 10px 20px;
		color: $white;
	}

	 .accept-btn:hover {
		background: $alt;
		color: #fff;
	}

	 .more-btn {
		color: #333;
		text-decoration: none;
		border-bottom: 1px dotted #777;
	}
}