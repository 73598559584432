body:not(.home){
	.parent{
		&--footer{
			// background: #EDEDED;
			// color:$base;
			@include row-margin;
		}
	}
	.row{
		&--top{
			background: $alt;
			color: $white;
			height: 120px;
			display: flex;
			align-items: center;
			justify-content: center;
			@include background;
			h1{
				text-align: center;
				text-transform: uppercase;
			}
			margin: 0 auto 50px;
		}
	}
}

.post-type-archive-administration{
	.row{
		.section__title{
			margin: 50px 0 80px;
			font-size: 25px;
		}
		&--administration{
			margin-bottom: 100px;
			.administration{
				&__thumbnail{
					@include background;
					background-size: contain;
					min-height: 300px;
					@include media-breakpoint-down(sm){
						min-height: 300px;
					}
				}
				&__content{
					h2{
						margin: 0;
					}
					h3{
						margin:10px 0 0;
					}
				}
				&__desc{
					line-height: 2;
					font-size: 15px;
				}
			}
		}
	}		
}
.page-template-archive{
	.row--blog{
		margin: 0 auto;
		.blog__elem{
			@extend .col;
			@extend .col--3;
			&:first-of-type{
				@extend .col--3;
			}
		}
		
	}
}
.single{
	.parent .row{
		&--top{
			height: 300px;
		}
		&--gallery{
			padding: 100px 0 0;
			@include gallery;
		}
	}
}
.page-kontakt{
	#map{
		height: 500px;
	}
	.contact__icon{
		padding: 0 0 0 35px;
		display: inline-flex;
		position: relative;
		min-height: 50px;
		align-items: center;
		&:before{
			font-family: $ico-font;
			position: absolute;
			left: 0;
			font-size:20px;
			text-align: center;
			width: 30px;
		}
		&--tel:before{
			content:"\f095"
		}
		&--adress:before{
			content:"\f041"
		}
		&--mail:before{
			content:"\f003"
		}
	}
	.contact__logo{
		height: 200px;
		@include center;
	}
	.wpcf7{
		&-form{
			display: flex;
			flex-wrap: wrap;
			margin:0 0 50px; 
			label{
				width: 100%;
				margin: 10px 0;
				display: inline-block;
			}
			input,textarea{
				margin: 10px 0 0;
				height: 40px;
				outline: 0;
				padding: 10px;
				border: 0;
				border-bottom: 2px solid $black;
				&:focus{
					border-bottom: 2px solid $alt;
					&::placeholder{
						// position: relative;
						left:-100%;
						// margin: -50px 0 0;
					}
				}
			}
			::placeholder{
				font-size: 14px;
				position: relative;
				left: 0px;
				transition: 1s;
			}
		}
		&-text{
			width: 100%;
		}
		&-textarea{
			width: 100%;
			resize: vertical;
			min-height: 150px;
		}
		&-submit{
			@extend .button;
			@extend .button--read-more;
			// border:1px solid $alt;
			// float: right;
			padding: 0 30px !important;
			&:hover{
				background:$alt;
				color:$white;
				// border-bottom:2px solid $alt;
			}
		}
	}
	.parent--footer{
		margin: 0px auto 0 !important;
	}
}