.error404{
	.col--1{
		flex-wrap:wrap;
		margin-bottom: 40px;
		h1,h2,h3{
			width: 100%;
			text-align: center;
		}
		h1{
			font-size:10vw;
			line-height: 1;
			font-weight: $bold;
			color:$alt;
			@include media-breakpoint-down( sm ){
				font-size: 12vw;
			}
			position: relative;
			z-index: 10;
			&:after{
				content: "ERROR";
				position: absolute;
				z-index: 1;
				top:-20px;
				font-size: 120%;
				left: 0;
				right: 0;
				color: rgba($base, 0);
				opacity: 0.2;
				text-shadow: 0 0 20px $base;
			}
		}
		h2{
			font-size:2vw;
			line-height: 1;
			font-weight: $bold;
			@include media-breakpoint-down( sm ){
				font-size: 4vw;
			}
		}
	}
}