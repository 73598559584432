.button{
	padding: 10px 20px;
	background: $white;
	text-transform: uppercase;
	font-size: 16px;
	font-weight: $bold;
	position: relative;
	z-index: 1;
	transition: .3s ease;
	cursor: pointer;
	display: inline-flex;
	text-align: center;
	&--center{
		margin-left: 50%;
		transform: translateX(-50%);
	}
	&--404{
		border: 1px solid $base;
		&:hover{
			background: $alt;
			color:$white;
		}
	}
	&--read-more{
		&:before,&:after{
			content: "";
			position: absolute;
			// background: $alt;
			transition: .3s;
			height: 10%;
			width: 10%;
			box-sizing: border-box;
		}
		&:before{
			top: 0;
			right: 0;
			border-top: 1px solid $alt;
			border-right: 1px solid $alt;
		}
		&:after{
			bottom: 0;
			left: 0;
			border-bottom: 1px solid $alt;
			border-left: 1px solid $alt;
		}
		&:hover{
			&:before, &:after{
				height: 100%;
				width: 100%;
			}
		}
	}
}