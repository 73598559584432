/*===========================================
=            Responsive nav menu            =
===========================================*/
.parent--header{
	position:fixed;
	top:0;
	right: 0;
	left:0;
	height: $nav-bar-height;
	display: flex;
	z-index: 90;
	#m_nav{
		display:none;
	}
	.row--header{
		margin: 0 auto;
		width: 90%;
	}
	nav{
		justify-self: flex-end;
		display: flex;
		align-items: center;
		font-size: 15px;
		>div{
			height: 100%;
			>ul{
				margin:0;
				padding:0;
				display: flex;
				height: 100%;
				justify-content:space-between;
				li{
					margin:0;
					padding:0;
					cursor:pointer;
					list-style: none;
					position: relative;
					a{
						display: inline-flex;
						text-align: center;
						height: 100%;
						align-items: center;
					}
					ul{
						display: none;
						list-style: none;
						margin: 0;
						padding: 0;
					}
					&:hover{
						ul{
							background: $white;
							display: block;
							right: 0;
							li{
								white-space: nowrap;
							}
						}
					}
					&.menu-item-has-children{
						> a{
							padding-right: 20px;
							position: relative;
							// height: initial;
							&:before{
								content: '\f0d7';
								font-family: $ico-font;
								position: absolute;
								right: 0;
								top:calc(50% - 10px);
								font-size: 20px;
								transition: .3s;
							}
							&:hover:before{
								transform: rotate(180deg);

							}
						}
					}
				}
			}
		}
	}
}
@include media-breakpoint-up( md ){
	.parent--header{
		// position:relative;
		#mobile-hamburger{
			display:none;
		}
		nav{
			max-width: 85%;
			float:right;
			ul li {
				a{
					padding: 10px 10px;
				}
				&:hover	ul{
					position: absolute;
					top: 100%;
				}
			}
			div > ul > li > a{
				position: relative;
				overflow:hidden;
				&:after{
					content: '';
					height: 3px;
					width: 100%;
					background: $alt;
					position: absolute;
					left: 0;
					bottom: -10px;
					transition: .3s;
				}
				&:hover:after{
					bottom: 0px;
				}
			}
		}
	}
}
@include media-breakpoint-down( sm ){
	.parent--header{
		// position:static;
		.row--header{
			width: 100%;
		}
		#mobile-hamburger{
			display:block;
			position:absolute;
			right:20px;
			top:20px;
			font-size:40px;
			z-index:90;
			cursor:pointer;
			width: 30px;
			height: 30px;
			text-align:center;
			span{
				border-radius: 3px;
				height: 5px;
				width: 100%;
				background: $base;
				box-shadow: 0 0 1px $white;
				position: absolute;
				left:0px;
				right:0px;
				display: inline-block;
				overflow: hidden;
				transition:all 0.3s ease;
				&:first-child,&:last-child{
					visibility:visible;
					width:100%;
					left:50%;
					transform:translateX(-50%);
				}
				&:nth-child(2){
					top: 10px;
					transform:rotate(0deg);
				}
				&:nth-child(3){
					top: 10px;
					transform:rotate(0deg);
				}
				&:last-child{
					top: 20px;
				}
			}
		}
		nav{
			position:fixed;
			background:$white;
			width:100%;
			max-height:0%;
			top:0;
			left:0;
			right:0;
			// bottom:100%;
			text-align:center;
			padding-top:0px;
			z-index:80;
			visibility:hidden;
			overflow:auto;
			transition: all 1s ease;
			border-bottom: 2px solid $base;
			> div{
				width: 100%;
			}
			&::-webkit-scrollbar { 
 			   display: none; 
			}
			ul{
				width:100%;
				flex-wrap:wrap;
				li{
					width:100%;
					a{
						padding: 10px 20px;
						height: initial !important;
						// width:100%;
					}
				}
			}
		}
		#m_nav[type=checkbox]:checked{
			& ~ nav{
				max-height: 100%;
				// bottom:0;
				visibility:visible;
				padding-top:$nav-bar-height;
			}
			& ~ #mobile-hamburger{
				.fixed{
					width:35px;				
				}
				span{
					&:first-child,&:last-child{
						visibility:hidden;
						top:10px;
						width:0px;
					}
					&:nth-child(2){
						transform: rotate(45deg);
					}
					&:nth-child(3){
						transform: rotate(-45deg);
					}
				}
			}
		}
	}
}