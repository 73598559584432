.single-project{
	.parent{
		.row{
			&--top{
				height:430px;
				.top__logo{
					img{
						max-height: 100px;
					}
					margin: 50px 0 0;
					text-align: center;
				}
				h1{
					margin-bottom: 0;
				}
				p{
					margin: 0;
					text-align: center;
				}
				background-blend-mode: darken;
				.top__mask{
					height: 100%;
					width: 100%;
					background: rgba($black, 0.2);
				}
			}
			&--main-content{

				.section__main-content{
					width: 80%;
					text-align: center;
					line-height: 1.5;
					font-size: 	16px;
					margin: 0 auto;
					@include media-breakpoint-down(sm){
						width: 100%;
					}
				}
				.project__points{
					span{
						position: relative;
						display: inline-flex;
						padding-bottom: 20px;
						height: 100%;
						align-items: center;
						text-align: center;
						font-weight: $bold;
						width: 100%;
						justify-content: center;
					}
					@for $i from 1 through 4 {
						&:nth-child(#{$i}) span:before{
							content: "#{$i}";
							position: absolute;
							color: #ededed;
							z-index: -1;
							font-size: 150px;
							font-weight: $xbold;
						}
					}
					@include media-breakpoint-down(sm){
						width: 50%;
						margin: 0 0 10px;
					}
				}
			}
			&--points{
				margin:50px 0;
				width: 100%;
			}
			.section__title{
				margin: 50px 0;
				font-size: 25px;
			}
			&.flex--start{
				justify-content: flex-start;
			}
			&--editor{
				min-height: 300px;
				background: $gray;
				font-size: 16px;
				.col{
					@include background;
					line-height: 2;
					@include media-breakpoint-down(md){
						width:100%;
						&.editor__image{
							min-height: 400px;
						}
					}
				}
			}
			&--program{
				line-height: 1.5;
				.program__desc{
					// text-align: center;
					font-size: 16px;
					align-items: center;
					display: flex;
					cursor: default;
					p{
						margin: 0;
					}
				}
				.col{
					padding-bottom: 30px;
				}
				.col--4{
					.inner{
						border:4px solid rgba($black, 0.25);
						padding: 15px;
						font-size: 15px;
						height: 200px;
						text-align: center;
						display: inline-flex;
						align-items: center;
						width: 100%;
						justify-content: center;
						position: relative;
						transition: .3s;
						cursor: default;
						font-weight: $bold;
						.increment{
							position: absolute;
							bottom: -20px;
							font-size: 30px;
							color:$gray;
							font-weight: $xbold;
							transition: .3s;
							background: $white;
							padding: 0 20px;
						}
					}
					&:hover{
						.inner{
							.increment{
								color:rgba($alt,1);
							}
							border:4px solid rgba($alt,1);
						}
					}
				}
			}
			&--leaders{
				.project-leader{
					&__thumbnail{
						// height: 300px;
						min-height: 200px;
						@include background;
						background-size: contain;
						&:after {
							content: "";
							display: block;
							padding-bottom: 100%;
						}
					}
					&__name{
						text-align: center;
						font-weight: $xbold;
						margin: 20px 0 0;
						font-size: 24px;
						@include media-breakpoint-down(md){
							font-size: 18px;
						}
					}
					&__desc{
						font-size: 15px;
						line-height: 1.4;
					}
				}
			}
			&--gallery{
				@include gallery;
				padding: 0;
			}
			&--main-content,&--points,&--editor,&--program,&--leaders,&--gallery{
				margin-bottom:20px;
			}
		}
	}
}