#image_extender{
	position: fixed;
	z-index: 99;
	background: rgba(0,0,0,0.9);
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: none;
	#container{
		width: 90vw;
		height: 90vh;
		position: absolute;
		top: 5vh;
		left: 5vw;
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
	}
	#exit{
		font-size: 80px;
		cursor: pointer;
		float: right;
		padding: 0 40px;
		position: relative;
		z-index: 99;
	}
	$arrow_color: darken(#fff, 40%);
	$border: 2px;
	.arrow{
		width: 5vw;
		height: 100vh;
		// background: red;
		position: absolute;
		display: flex;
		justify-content:center;
		cursor: pointer;
		span{
			width: 3vw;
			height: 3vw;
			// background: darken(#fff, 40%);
			display: block;
			top:50%;
			position: absolute;
		}
		&--prev{
			left: 0;
			span{
				border-top:$border solid $arrow_color;
				border-left:$border solid $arrow_color;
				transform: rotate( -45deg );
			}
		}
		&--next{
			right: 0;
			span{

				border-top:$border solid $arrow_color;
				border-right:$border solid $arrow_color;
				transform:rotate( 45deg);
			}
		}
	}
}