@import url('https://fonts.googleapis.com/css?family=Muli:300,400,700,900');
@font-face {
  font-family: 'FontAwesome';
  src: url('../fonts/fontawesome-webfont.woff') format('woff'),
    url('../fonts/fontawesome-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
/*=================================
=            Variables            =
=================================*/

@import "components/variables";

/*=====  End of Variables  ======*/

/*==============================
=            Mixins            =
==============================*/

@import "mixins/breakpoints";
@import "mixins/clearfix";
@import "mixins/helpers";

/*=====  End of Mixins  ======*/

/*==================================
=            components            =
==================================*/

@import "normalize.scss/normalize.scss";
@import "components/rwd";
@import "components/navigation";
@import "components/grid";
@import "components/buttons";
@import "components/gallery";
// @import "components/acordeon";
// @import "components/psd";

/*=====  End of components  ======*/

/*=====================================
=            Plugins style            =
=====================================*/

@import "aos/src/sass/aos.scss";
// @import "components/tabs";
@import "components/scrollTop";
// @import "components/parallax";
@import "components/cokies";

/*=====  End of Plugins style  ======*/


/*======================================
=            Global  styles            =
======================================*/

html{
	font-family: $base-font;
	font-size: $font-size;
	color: $base;
	margin: 0 !important;
}
body{
	width: 100%;
	height: 100%;
	overflow-y: scroll;
}
a{
	text-decoration: none;
	color: inherit;
}
*{
	box-sizing: border-box;
}
.wrapper{
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
}
.container{
	margin: 0 auto;
}
.row{
	display: flex;
	flex-wrap:wrap;
	justify-content:space-between;
}


@import "pages/main";
@import "pages/index";
@import "pages/single-project";
@import "pages/404";

