#go_to_top{
	position: fixed;
	bottom: 200px;
	right: 0;
	height: 50px;
	width: 50px;
	background: $alt;
	z-index: 99;
	cursor: pointer;
	display: none;
	&:before,&:after{
		content:"";
		height: 2px;
		background: $white;
		width: 15px;
		position: absolute;
		left:20%;
		top:33%;
		transform-origin: right center;
	}
	&:before{
		transform: rotate(-45deg);
	}
	&:after{
		transform: rotate(-135deg);
	}
}