@mixin center{
	margin-left: 50%;
	transform: translateX(-50%);
}
@mixin background{
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}
@mixin center-both{
	top:50%;
	left:50%;
	transform: translate(-50%,-50%);
	position: absolute;
}

@mixin absfull{
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}
@mixin row-margin{
	margin: 80px auto 0;
}

@mixin gallery{
	.gallery{
		&__wrap{
			width: 100%;
		}
		&__item{
			height: 260px;
			float: left;
			@extend .col;
			padding-bottom: 30px;
			width: 33.3%;
			cursor: pointer;
			@include media-breakpoint-up(lg){
				&:nth-child(1){
					width: 66.6%;
					height: 520px;
					float: right
				}
			}
			@include media-breakpoint-down(md){
				// height: 400px;
				width: 50%;
				padding: 0 5px 10px;
			}
			@include media-breakpoint-down(xs){
				height: 400px;
				width: 100%;
			}
			&:hover{
				.gallery__inner:after{
					// content: url(../img/zoom.png);
					visibility: visible;
					opacity: 1;
				}
			}
		}
		&__inner{
			height: 100%;
			width: 100%;
			opacity: 0.9;
			@include background;
			position: relative;
			&:after{
				content: url(../img/zoom.png);
				width: 100%;
				height: 100%;
				position: absolute;
				display: flex;
				justify-content: center;
				align-items: center;
				background: rgba($black,.5);
				visibility: hidden;
				opacity: 0;
				transition: .5s;
			}
			// &:before{
			// 	content: "";
			// 	position: absolute;
			// 	height: 100%;
			// 	width: 100%;
			// 	z-index: -1;
			// }
		}
	}
}