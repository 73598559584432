.col{
	padding: 0 15px;
	@include media-breakpoint-down (sm) {
		padding:0;
	}
	&--2 {
		width:50%;
		@include media-breakpoint-down (sm) {
			width:100%;
		}
	}
	&--3{
		width: 33.3%;
		@include media-breakpoint-down (md) {
			width:50%;
			padding: 0 15px;
		}
		@include media-breakpoint-down (xs) {
			width:100%;
		}
	}
	&--4{
		width: 25%;
		@include media-breakpoint-down (md) {
			width:50%;
		}
		@include media-breakpoint-down (sm) {
			width:100%;
		}
	}
	&--1{
		width:100%;
	}
	&--2of3{
		width: 66.6%;
		@include media-breakpoint-down (sm) {
			width:100%;
		}
	}
	&--1of3{
		width: 33.3%;
		@include media-breakpoint-down (sm) {
			width:100%;
		}
	}
	&--3of4{
		width: 75%;
		@include media-breakpoint-down (sm) {
			width:100%;
		}
	}
	&--1of4{
		width: 25%;
		@include media-breakpoint-down (sm) {
			width:100%;
		}
	}

}