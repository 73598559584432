$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1170px
) !default;

$base-font:		'Muli', sans-serif;
$ico-font:		'FontAwesome';
$font-size:		18px;

$white:			#fff;
$black:			#000;
$base:			#343434;
// $alt:			#990000;
$alt:			#007fa6;
$gray:			#EDEDED;

$light:			300;
$normal:		400;
$bold:			700;
$xbold:			900;

$nav-bar-height:	70px;